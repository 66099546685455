import Cart from '../cart/Cart'
import Footer from '../comman/Footer'
import Navbar from '../comman/Navbar'
export default function CartPage() {
    return (
        <>
            <Navbar>
                <Cart></Cart>
            </Navbar>
            <Footer></Footer>
        </>
    )

}